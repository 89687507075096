<template>
  <div>
    <div class="title">
      工龄分配置
    </div>
    <ul>
      <li>
        <label>按月结算</label>
        <el-input-number v-model="form.seniorityMonthlySettlement" style="width:120px" size="small" controls-position="right"></el-input-number>
        <el-tooltip placement="top">
          <div slot="content">提示：系统将从员工入职月到工龄分截止月期间计算，不足整月，都默认按照满月计算。</div>
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </li>
      <li>
        <label>工龄分截至时间</label>
        <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" type="month" v-model="form.seniorityClearingDate" style="width:150px" size="small"></el-date-picker>
      </li>
    </ul>

    <div class="title">
      启动分/基础分配置
    </div>
    <ul>
      <li>
        <label>基础</label>
        <el-input-number v-model="form.baseScore" style="width:120px" size="small" controls-position="right"></el-input-number>
      </li>
    </ul>

    <div class="title">
      日常奖扣
      <span>(记录人奖分)</span>
    </div>
    <ul>
      <li v-for="(item,index) in form.dailyConfig.record" :key="item.id">
        <label>奖扣人次:</label>
        <label>人次</label>
        <el-input-number v-model="item.dailyTime" size="small" controls-position="right"></el-input-number>
        <label>奖分</label>
        <el-input-number v-model="item.dailyScore" size="small" controls-position="right"></el-input-number>
        <i @click="add(form.dailyConfig.record,1)" class="icon el-icon-circle-plus-outline"></i>
        <i @click="remove(form.dailyConfig.record,index)" v-show="form.dailyConfig.record.length>1 && index!=0" class="icon el-icon-remove-outline"></i>
      </li>

    </ul>

    <div class="title">
      日常奖扣
      <span>(事件数奖分)</span>
    </div>
    <ul>
      <li v-for="(item,index) in form.dailyConfig.event" :key="item.id">
        <label>奖扣事件:</label>
        <label>事件数</label>
        <el-input-number v-model="item.dailyTime" size="small" controls-position="right"></el-input-number>
        <label>奖分</label>
        <el-input-number v-model="item.dailyScore" size="small" controls-position="right"></el-input-number>
        <i @click="add(form.dailyConfig.event,2)" class="icon el-icon-circle-plus-outline"></i>
        <i @click="remove(form.dailyConfig.event,index)" v-show="form.dailyConfig.event.length>1 && index!=0" class="icon el-icon-remove-outline"></i>
      </li>
    </ul>

    <div class="title">
      任务大厅
      <span>(记录人奖分)</span>
    </div>
    <ul>
      <li>
        <label>奖分</label>
        <el-input-number v-model="form.taskAwardScore" size="small" controls-position="right"></el-input-number>
      </li>
    </ul>
    <div class="title">
      生日奖分
    </div>
    <ul>
      <li>
        <label>奖分</label>
        <el-input-number v-model="form.birthdayScore" size="small" controls-position="right"></el-input-number>
      </li>
    </ul>

    <div class="title">
      考勤设置
    </div>
    <ul>
      <li>
        <label>全勤奖分</label>
        <el-input-number v-model="form.attendanceAllScore" size="small" controls-position="right"></el-input-number>
      </li>
      <li>
        <label>事假每天扣分</label>
        <el-input-number v-model="form.attendancePersonalScore" size="small" controls-position="right"></el-input-number>
      </li>
      <li>
        <label>病假每天扣分</label>
        <el-input-number v-model="form.attendanceSickScore" size="small" controls-position="right"></el-input-number>
      </li>
      <li>
        <label>其他每天扣分</label>
        <el-input-number v-model="form.attendanceOtherScore" size="small" controls-position="right"></el-input-number>
      </li>
    </ul>

    <div class="title">
      产值配置
    </div>
    <ul>
      <li>
        <label>虚产值转积分</label>
        <el-input-number v-model="form.outputVirtualConvert" size="small" controls-position="right"></el-input-number>
      </li>
      <li>
        <label>实产值转积分</label>
        <el-input-number v-model="form.outputRealConvert" size="small" controls-position="right"></el-input-number>
      </li>
      <li>
        <label>创富产值转积分</label>
        <el-input-number v-model="form.outputSomeConvert" size="small" controls-position="right"></el-input-number>
      </li>
    </ul>

    <div class="title">
      奖励任务结算配置
    </div>
    <ul>
      <li>
        <label>奖扣任务考核日期</label>
        <el-input-number v-model="form.taskCheckDate" :min="1" :max="15" style="width:120px" size="small" controls-position="right"></el-input-number>
        <el-tooltip placement="top">
          <div slot="content">提示：系统将在考核日期当晚对上月的奖扣任务进行结算</div>
          <i class="el-icon-warning"></i>
        </el-tooltip>
      </li>
    </ul>
    <div class="title">
     App版本更新
    </div>
    <ul>
      <li>
         <label> 是否开启</label>
        <el-switch v-model="form.openControl" :active-value="1" :inactive-value="0">
        </el-switch>
      </li>

    </ul>
    <div class="btndiv">
      <el-button size="small" type="primary" v-if="isAuth('platform-config:systemconf:save')" :loading="loading" @click="save">提 交</el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        attendanceAllScore: null, //全勤奖分
        attendanceOtherScore: null, //	其他扣分
        attendancePersonalScore: null, //事假扣分
        attendanceSickScore: null, //病假扣分
        baseScore: null, //基础分
        birthdayScore: null, //生日奖分		false
        createDate: null, //	创建时间		false
        creator: null, //	创建者		false
        dailyConfig: {
          record: [],
          event: [],
        }, //	日常配置json		false
        delFlag: null, //	删除标识 0：未删除 1：删除		false
        outputRealConvert: null, //	实产值转积分		false
        outputSomeConvert: null, //	创富值转积分		false
        outputVirtualConvert: null, //	虚拟产值转积分		false
        seniorityClearingDate: null, //	工龄分结算日期		false
        seniorityMonthlySettlement: null, //	工龄分按月给分		false
        taskAwardScore: null, //	任务奖分		false
        taskCheckDate: null, //	奖扣考核日期
      },
      loading: false, //数据提交状态
    };
  },
  created() {
    this.sel();
  },
  methods: {
    async sel() {
      var res = await this.$get("/platform-config/systemconf/page", {
        page: 1,
        limit: 1,
      });
      if (res && res.code === 0) {
        if (res.data.list[0]) {
          this.form = res.data.list[0];
        } else {
          this.form = {}
        }
        this.checkList();
      }
    },
    async save() {
      var data = {
        ...this.form,
      };
      this.loading = true;
      if (data.createDate) delete data.createDate;
      if (data.updateDate) delete data.updateDate;
      if (data.creator) delete data.creator;
      if (data.updater) delete data.updater;
      if (data.id) delete data.id;

      var res = await this.$post(
        "/platform-config/systemconf/saveSystemConf",
        data
      );
      this.loading = false;
      if (res && res.code === 0) {
        this.$message.success("保存成功！");
      }
    },
    checkList() {
      // 检查dailyConfig 配置
      if (!this.form.dailyConfig) {
        this.form.dailyConfig = {};
        this.form.dailyConfig.record = [];
        this.add(this.form.dailyConfig.record, 1);
        this.form.dailyConfig.event = [];
        this.add(this.form.dailyConfig.event, 2);
      } else {
        if (!this.form.dailyConfig.record) {
          this.form.dailyConfig.record = [];
          this.add(this.form.dailyConfig.record, 1);
        } else {
          if (!this.form.dailyConfig.record.length)
            this.add(this.form.dailyConfig.record, 1);
        }
        if (!this.form.dailyConfig.event) {
          this.form.dailyConfig.event = [];
          this.add(this.form.dailyConfig.event, 2);
        } else {
          if (!this.form.dailyConfig.event.length)
            this.add(this.form.dailyConfig.event, 2);
        }
      }
    },
    add(arr, type) {
      arr.push({
        id: this.$createId(),
        dailyScore: null,
        dailyTime: null,
        dailyType: type,
      });

      this.$forceUpdate();
    },
    remove(arr, index) {
      if (arr.length <= 1) {
        this.$message.warning("最少有一条配置");
        return;
      }
      arr.splice(index, 1);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped >
.title {
  color: #409eff;
  font-size: 18px;
  padding-left: 20px;
  span {
    color: #696969;
    font-size: 14px;
  }
}
ul {
  list-style: none;
  padding-left: 60px;
  min-height: 60px;
  li {
    line-height: 50px;
    label {
      margin-left: 20px;
      margin-right: 5px;
    }
  }
}
.btndiv {
  text-align: center;
}
.icon {
  font-size: 22px;
  color: #409eff;
  margin-left: 20px;
}
</style>
